// @ts-ignore
export default defineI18nConfig(() => ({
    // defaultLocale: 'en',
    // vueI18nLoader: true,
    // strategy: 'no_prefix',

    // lazy: true,
    // locale: 'en',
    // legacy: false,
    // langDir: 'locales/',
    // locales: [
    //     {
    //         code: 'en',
    //         file: 'en.js'
    //     },
    //     {
    //         code: 'ru',
    //         file: 'ru.js'
    //     },
    // ],
    // globalInjection: true,
    // detectBrowserLanguage: {
    //     useCookie: true,
    //     redirectOn: 'root',
    //     cookieKey: 'i18n_redirected',
    // }
}));
